.CodeBox {
  .text {
    background: var(--backgroundSub);
    border-radius: 12px;
    padding: 8px 12px;
    padding-top: 4px;
    padding-bottom: 32px;
    code {
    }
  }
  button {
    width: 100%;
    transform: translateY(-24px);
    background: var(--text2);

    &:hover {
      background: var(--text3);
    }
    &.finish {
      background: var(--sub);
    }
  }
}
