.PageRegister {
  height: 100vh;
  overflow-y: scroll;
  .DashdesignBackendIcon {
    height: 44px;
  }

  .container {
    max-width: 1200px;
    padding: 32px;

    .lol {
      border-radius: 12px;
      background: var(--background);
      box-shadow: 5px 5px 10px #d4dde8, -5px -5px 10px #eaf5ff;
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      i {
        font-size: 128px;
        color: var(--text1);
      }
    }
    h2 {
      font-size: 18px;
      margin: 16px;
    }
    input {
      &::placeholder {
        color: #949fb0;
        font-weight: 400;
        letter-spacing: 4px;
        transition: 0.2s;
      }
      border: none;
      flex: 1;
      min-width: 400px;
      margin-bottom: 16px;
      padding: 12px 16px;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: var(--text1);
      font-weight: 800;
      border-radius: 12px;
      background: var(--background);
      border: var(--defaultInputBorder);
    }
    button {
      border-radius: 12px;
    }
    select {
      border: none;
      flex: 1;
      min-width: 400px;
      margin-bottom: 16px;
      padding: 12px 16px;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: var(--text1);
      font-weight: 800;
      border-radius: 12px;
      background: var(--background);
      border: var(--defaultInputBorder);
    }
    option {
      font-size: 16px;
      letter-spacing: 0.5px;
      color: var(--text1);
      font-weight: 800;
    }
    input[type="checkbox"] {
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin-right: 12px !important;
      background: var(--background);
      box-shadow: 5px 5px 11px #d4dde8, -5px -5px 11px #eaf5ff;
    }
  }
}
