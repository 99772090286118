.EntryNewBuyPage {
  overflow-y: auto;
  max-height: calc(100vh - 92px);

  .fixedPush {
    height: 140px;
  }
  .fixed {
    background: var(--backgroundSub);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 0 12px 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  }
}
