.FieldList {
  .RequiredLabel {
    background: var(--red);
    font-weight: 700;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px;
    margin-bottom: 8px;
    display: inline-block;
  }
}
