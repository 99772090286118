.OpeningHours {
  overflow-x: auto;

  .day {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid var(--backgroundSub);

    h4 {
      margin-bottom: 0;
      color: var(--sub);
      font-weight: 500;
      width: 36px;
    }

    .red {
      font-weight: 600;
      font-size: 16px;
    }

    .TimeSpanInput {
      margin-right: 4px;
      padding-right: 4px;
      border-right: 1px solid var(--backgroundSub);
      margin-bottom: 0;
      input {
        color: var(--text1);
        font-weight: 700;
        text-align: end;
        background: none;
        border: none;
        padding: 0;
        font-size: 16px;
        &::-webkit-calendar-picker-indicator {
          background: none;
          display: none;
        }
      }
      span {
        color: var(--text3);
        font-weight: 200;
        margin: 0 4px;
      }
    }

    button {
      background: none;
      padding: 0;
      margin: 4px;
      align-items: center;
      justify-content: center;

      i {
        transition: 0.2s;
        font-size: 16px;
        color: var(--sub);
      }

      &:focus {
        i {
          transition: 0.2s;
          font-size: 18px;
          color: var(--red);
        }
      }
    }
  }
}
