.AiButton {
  font-size: 14px;
  padding: 2px 8px;
  background: var(--green);
  color: var(--green_);
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  margin: 4px;

  span {
    margin-left: 4px;
    font-weight: 700;
  }
}
