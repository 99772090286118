.WeatherInput {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    .option {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin: 0 4px 4px 0;
      border-radius: 8px;
      background: var(--backgroundSub);
      // border: 2px solid #000;

      padding: 4px 8px;

      &.isActive {
        background: var(--sub);
        label {
          color: var(--sub_);
        }
      }

      input {
        width: 32px;
      }
    }
  }
}
