.IconInput {
  padding: 8px;

  i {
    font-size: 44px;
    color: var(--sub);
  }

  span.change {
    display: block !important;
    color: var(--sub);
    text-decoration: underline;
    cursor: pointer;
    padding: 16px;
  }
  .iconButton {
    width: 132px !important;
    background: transparent;
  }
}

.iconButton {
  width: 24px !important;
  background: transparent;
  font-size: 32px;
  transition: 0.6s;
  i {
    transition: 0.6s;
  }
  &:hover {
    transition: 0.2s;
    transform: scale(1.1);

    i {
      transition: 0.2s;
      color: var(--sub) !important;
    }
  }
}
