.Emulator {
  --cityColor: var(--green);

  --statusBarHeight: 32px;
  --deviceWidth: 360px;

  border-radius: 24px;
  background: var(--backgroundSub);
  max-height: 240px;
  // border: 1px solid var(--backgroundSubSub);

  padding: 16px;
  margin-top: -0px;

  &.mobileShowEmulator {
    --deviceWidth: calc(100% + 10px);
    display: block !important;
    position: fixed;
    top: 00;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    height: 100vh;
    background: red;
    z-index: 99999;
    .options,
    .ZoomBox .selector {
      display: none !important;
    }
    .screen {
      display: block !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;

      border: none !important;
      border-radius: 0 !important;

      .StatusBar {
        display: none !important;
      }
    }
  }

  .options {
    padding: 2px 12px;

    .live {
      background: var(--red);
      color: var(--red_);
      font-weight: 800;
      padding: 2px 4px;
      border-radius: 4px;
      display: inline-block;
      font-size: 8px;
    }

    .darkModeToggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        padding: 0 4px;
      }
      i {
        padding-top: 4px;
        font-size: 28px;
        color: var(--sub);
        &.fa-toggle-off {
          --fa-primary-color: var(--sub);
          --fa-secondary-color: var(--backgroundSubSub);
        }
        &.fa-toggle-on {
          --fa-primary-color: var(--sub);
          --fa-secondary-color: var(--background);
        }
      }
    }
  }

  .device {
    width: var(--deviceWidth);
    border-radius: 50px;
    background: #0d4ba8;
    padding: 4px;

    .screen {
      border: 10px solid #111;
      height: 752px;
      border-radius: 48px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      * {
        transition: 0.2s;
      }

      .StatusBar {
        display: flex;
        justify-content: center;
        z-index: 1000;

        .notch {
          height: var(--statusBarHeight);
          width: 120px;
          background: #111;
          position: absolute;
          border-bottom-right-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        .inner {
          width: 100%;
          height: var(--statusBarHeight);

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 16px;

          span {
            font-size: 12px;
            font-weight: 700;
          }
          i {
            font-size: 12px;
            margin: 0 4px;
            --fa-primary-color: #000;
            --fa-secondary-color: rgb(0, 0, 0, 0.3);

            &.fa-battery-full {
              font-size: 18px;
              --fa-primary-color: rgb(4, 197, 4);
              --fa-secondary-color: rgb(0, 0, 0);
              transform: translateY(2px);
            }
          }
        }
      }

      .content {
        margin-top: calc(-1 * var(--statusBarHeight));
        width: 120%;
        height: 100%;
        background: var(--backgroundSub);

        > div {
          width: calc(100% - 58px);
          height: 100%;
        }
      }
    }
  }
}
