/* Popup */
.PageDashboard {
  padding: 20px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .fastActions {
    .item {
      padding: 8px 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: var(--background);
      border-bottom: var(--defaultBorder);
      cursor: pointer;
      > .fad {
        font-size: 54px;
        margin-right: 0px;
        padding: 16px;
        transition: 1s;
      }
      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        transition: 1s;
      }
      p {
        transition: 1s;
        margin-bottom: 0;
      }
      &:hover {
        h3 {
          transition: 0.3s;
          padding-left: 4px;
        }
        p {
          transition: 0.3s;
          padding-left: 8px;
        }
        .fad {
          transition: 0.3s;
          transform: scale(1.1);
        }
      }
    }
  }
  a {
    text-decoration: initial;
    color: initial;
  }
}
