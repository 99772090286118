.AdminCityTiles {
  .Tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 24px;
    padding: 12px 24px;
    max-width: 600px;
    border-radius: 12px;
    background: var(--backgroundSub);

    .inner {
      cursor: pointer;
      i {
        font-size: 32px;
      }
      span.title {
        font-size: 20px;
        margin: 0 16px;
        font-weight: 600;
      }
    }
  }
}
