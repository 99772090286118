.HomeSwitch {
  height: 100vh;
  width: 100vw;

  overflow: hidden;

  display: flex;
  flex-direction: column;

  .content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .inner {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
}

.page {
  height: calc(100vh - 72px);
  padding-bottom: 128px;
  overflow-y: auto;
  width: 100%;
}
