.SideBar {
  width: 240px;
  min-width: 240px;
  margin-bottom: 12px;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
  z-index: 900;

  background: var(--background);

  .Area {
    padding: 0 0;
    margin-bottom: 32px;
    width: 100%;
    .areaTitle {
      color: var(--text1);
      font-weight: 800;
    }

    .item {
      padding: 2px;

      .NavLink {
        color: var(--text3);
        display: block;
        transition: 1s;

        &.active,
        &:hover {
          span {
            font-weight: 600;
            transition: 0.2s;
            color: var(--sub) !important;
          }
        }

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          font-size: 14px;

          @media (max-width: 768px) {
            font-size: 19px;
          }
        }
      }

      .children {
        margin-top: 12px;
        padding-top: 12px;
        border-left: 1px solid #aaa;
        padding-left: 12px;
        margin-bottom: 12px;
      }
    }
  }
}
