.ListEditor.ProductInputList {
  .exists {
    span {
      display: none;
    }
  }

  .Value {
    padding: 12px 16px;
    background: var(--backgroundSub);
    border-radius: 16px;

    input {
      border: none;
      background: none;
      &.title {
        font-weight: 500;
        font-size: 24px;
      }
      &.title {
        font-weight: 500;
        font-size: 24px;
      }
    }
  }
}
