body {
  max-height: 100vh;
  overflow: hidden;

  background: var(--background) !important;
  color: var(--text3);

  a {
    text-decoration: none;
    color: var(--sub);
  }

  h1,
  h2,
  b {
    color: var(--text1);
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--text2);
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    color: var(--text3);
  }
  p {
    font-size: 14px;
  }
  .error {
    color: var(--red);
    font-weight: 600;
    font-size: 18px;
  }

  .neu {
    border-radius: 8px;
    background: var(--background);
    border: var(--defaultBorder);
  }
  .neu-inner {
    border-radius: 6px;
    background: var(--background);
    border: var(--defaultBorder);
  }

  button.nice {
    flex: 1;

    padding: 12px 64px;
    border-radius: 12px;
    background: var(--background);
    border: var(--defaultInputBorder);

    font-size: 20px;
    color: rgb(90, 89, 89);
    text-transform: none;
    font-weight: 600;
  }

  button.nice.nice-dark {
    background: var(--backgroundSub);
  }

  button.nice span {
    display: block;
    font-size: 16px;
    opacity: 0.75;
  }

  input.asText {
    box-shadow: none !important;
  }

  .EntryCropper .ReactCrop__crop-selection {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../svgs/cropperOverview.svg");

    /* border-image-source: none;
  border: 2px solid #fff;
  box-shadow: 0 0 1px 1px #000;
  border-radius: 8px; */
  }
  .EntryCropper .ReactCrop__drag-handle::after {
    border-radius: 3px;
    background: #111;
  }

  .modal {
    .modal-dialog {
      max-width: 900px;

      button.close {
        background: transparent;
        font-size: 42px;
      }
    }
  }
}
