.MainLogo {
  // padding: 12px;
  font-weight: 800;
  font-size: 32px;

  transition: 0.8s;

  * {
    font-family: "Poppins", "Helvetica Neue", Arial, sans-serif;
    font-weight: 800;
    color: var(--sub);
  }

  .sub {
    transition: 0.8s;
    color: var(--main);
  }

  &.white {
    transition: 0.84s;
    color: #fff;
    .sub {
      transition: 0.8s;

      color: #fff;
      opacity: 0.8;
    }
  }
}
