.Tile {
  margin-right: 16px;
  margin-bottom: 16px;

  text-align: center;
}

.Tile .item {
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 72px;
  width: 72px;
  border-radius: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Tile .item i {
  font-size: 32px;
}

.Tile span.title {
  font-size: 12px;
}
