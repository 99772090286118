.SubPage {
  width: calc(var(--deviceWidth) - 27px);
  height: 100%;
  overflow-y: scroll;

  .navTop {
    background: var(--backgroundSub);

    z-index: 10;

    position: relative;
    padding: 8px 16px;
    padding-top: calc(var(--statusBarHeight) + 8px);

    border-radius: 0 0 16px 16px;

    box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.4);

    span {
      font-weight: 600;
      font-size: 15px;
      i {
        font-size: 14px;
        margin-right: 4px;
      }
    }
  }

  .children {
    margin-top: -12px;
  }
}
