.Gallery {
  display: flex;
  border-radius: 6px;
  background-color: var(--backgroundSub);
  overflow-x: auto;
  width: 100%;

  .dummy {
    height: 250px;
    width: 100%;
    margin: 10px;

    h1 {
      margin: 20px;
      text-align: center;
      vertical-align: middle;
    }

    p {
      text-align: center;
      vertical-align: middle;
    }
  }
}
