.DropdownInput {
  .list {
    margin-top: -8px;
    padding-top: 8px;
    background: var(--backgroundSub);
    max-height: 200px;
    overflow: scroll;
    border: 1px solid var(--text4);
    border-top: 0;

    padding-left: 16px;
    cursor: pointer;
  }
}
