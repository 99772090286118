.CropperComponent {
  .ReactCrop {
    img {
      width: 100%;
    }
  }
  .disable_crop {
    width: 0;
    height: 0;
  }
}
