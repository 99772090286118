.ImageInput {
  button {
    background: transparent;
    padding: 0;
    padding-top: 12px;

    i {
      font-size: 24px;
      margin-right: 12px;
    }
  }
}
.modal-content {
  background-color: var(--backgroundSub);
  border: 1px solid var(--sub);

  .modal-header {
    background: var(--background);
    border: none;
  }
}
