.ContinueOnMobile {
  .qrBox {
    background: #fff;
    padding: 12px;
    border-radius: 12px;
    svg {
      max-width: 320px;
    }

    .LoginCode {
    }

    .unlockBox {
      position: relative;
      padding: 16px;

      svg {
      }

      .overlay {
        position: absolute;
        backdrop-filter: blur(8px);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: calc(320px + 32px);

        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        .inner {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          border: 12px solid var(--backgroundSub);
          background: var(--background);
          box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);

          height: 70%;
          max-width: 70%;
          border-radius: 50%;

          span {
            font-weight: 700;
            margin-top: 12px;
            text-align: center;
          }

          .icons {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transform: translateX(28px);
            .fas {
              font-size: 64px;
              position: relative;
            }
            .fa-lock {
              opacity: 1;
              transition: 0.3s;
              color: var(--sub);
            }
            .fa-unlock {
              opacity: 0;
              transition: 0.3s;
              transform: translateX(-100%);
              color: var(--sub);
            }
          }

          &:focus {
            transition: 0.1s;
            backdrop-filter: blur(4px);
          }
          &:hover {
            backdrop-filter: blur(6px);
            transition: 0.2s;
            .icons {
              .fa-lock {
                transition: 0.3s;
                opacity: 0;
              }
              .fa-unlock {
                color: var(--red);
                transition: 0.3s;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
