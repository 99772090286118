.EventItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  border-radius: 12px;
  cursor: pointer;
  span.date {
    color: var(--red);
    font-weight: 700;
    font-size: 14px;
    display: block;

    i {
      color: var(--red);
      margin-left: 12px;
      margin-right: 4px;
    }
  }
  span.title {
    color: var(--text1);
    font-weight: 800;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    word-wrap: no-break;
    margin-bottom: 8px;
    span {
      color: var(--text3);
      font-weight: 500;
      font-size: 16px;
      a {
        color: var(--text3);
      }
      i {
        color: var(--text3);
        font-size: 16px;
        margin-right: 3px;
        margin-left: 12px;
      }
      &:hover {
        color: var(--text2);

        a,
        i {
          color: var(--text2);
        }
      }
    }
  }
  .options {
    > * {
      margin-right: 12px;
    }
  }
  .imageBox {
    width: 100%;
    .ratio {
      .image {
        border-radius: 12px;
        overflow: hidden;
        display: block;
        margin-right: 12px;
        width: 100%;
        background: #eee;
        background-position: center;
        background-size: auto 100%;
        transition: 0.6s;
      }
    }
  }

  .details {
    display: flex;
    flex-direction: row;
    transition: 0.6s;
  }

  &:hover {
    background: var(--backgroundSub);

    .details {
      opacity: 1;
      transition: 0.2s;
    }
    .imageBox {
      .ratio .image {
        transition: 0.2s;
        background-size: auto 110%;
      }
    }
    .tags {
      a {
        text-decoration: underline !important;
        &:hover {
          color: var(--sub);
          i {
            color: var(--sub);
          }
        }
      }
    }
  }
}
.edit {
  font-size: 16px;
  font-weight: 600;
  padding-right: 16px;
  cursor: pointer;
  color: var(--sub);
  padding: 4px 8px;
  display: inline-block;
  &:hover {
    color: var(--sub);
    i {
      transform: scale(1.2);
    }
  }

  i {
    padding: 4px;
    color: var(--sub);
    font-size: 16px;
  }

  &.del {
    color: var(--red);
    i {
      color: var(--red);
    }
  }
}
.fade-in {
  transition: opacity 0.2s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 0.2s ease;
}
