.EntryPage_EmulatorScreen {
  .ImageCarousel {
    width: 100%;
    min-height: 220px;
    background: red;

    background-color: #8ec5fc;
    background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

    img {
      width: 100%;
    }
  }

  .cont {
    position: relative;
    border-radius: 16px 16px 0 0;
    background: var(--background);
    z-index: 100000;
    margin-top: -12px;
    box-shadow: 0 -2px 2px 0px rgba(0, 0, 0, 0.1);

    .titleContainer {
      padding: 12px;
    }

    .FastButtons {
      flex-direction: column;
      display: flex;
      padding: 16px;
      padding-right: 0;
      justify-content: space-around;
      margin-top: 16px;

      .FastButton {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        cursor: pointer;

        i {
          margin-bottom: 4px;
          font-size: 22px;
        }
        small {
          font-size: 12px;
        }
      }
    }
  }

  .Areas {
    .titleItem {
      padding: 4px 12px;
      border-radius: 12px;
      background: var(--backgroundSubBorder);
      color: var(--text1);
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      &.isActive {
        background: var(--text2);
        color: var(--background);
      }
    }

    .areaContent {
      margin-top: 8px;
      .style {
        padding: 16px;
        padding-bottom: 0;
      }
    }
  }
}
