.TitleBar {
  padding: 0 24px;
  border-bottom: 1px solid var(--backgroundSub);
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.05);

  .mainLogo {
    height: 72px;
    padding: 12px;
    transform: translateY(2px);
  }

  .pb {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: var(--sub);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      text-align: center;
      font-size: 20px;
      font-weight: 800;
      color: #fff;
      letter-spacing: 2px;
    }
  }
}
