.ZoomBox {
  .selector {
    display: flex;
    justify-content: center;
    span {
      font-weight: 600;
      padding: 0 4px;
      &::selection {
        background: initial;
      }
    }
    i {
      cursor: pointer;
      color: var(--sub);
      font-size: 16px;
    }
  }

  .wrap {
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    transition: 0.4s;
  }
}
