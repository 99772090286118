.PageDashboard .DefaultTile {
  padding: 16px 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  text-align: center;

  height: 100%;

  * {
    transition: 0.6s;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .iconBox {
      display: block;
      width: 160px;
      height: 160px;
      border-radius: 10%;
      background-image: linear-gradient(
        184deg,
        var(--sub) -20%,
        var(--main) 150%
      );
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      i,
      svg {
        margin-top: -8px;
        transform: translateY(6px);
        color: #fff;
        font-size: 80px;
      }
    }

    h2 {
      font-size: 18px;
    }
  }
  .hoverBox {
    opacity: 0;
    transition: 1s;
  }

  &:hover {
    * {
      transition: 0.2s;
    }
    .iconBox i {
      transform: scale(1.1) translateY(4px);
    }
    .hoverBox {
      transition: 1s;
      opacity: 1;
    }
  }
}
