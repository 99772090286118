.DefaultLoader {
  align-self: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -4px; //optisches zentrieren (Gegenstück zum span)

  i {
    color: var(--text3);
    font-size: 120px;
  }
  span {
    color: var(--text3);
    margin-left: 8px; //optisches zentrieren
    margin-top: 12px;
    font-size: 18px;
  }
}
