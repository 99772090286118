.IconHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  h3 {
    margin: 0;
    margin-left: 8px;
  }

  .iconBox {
    background: var(--cityColorSub);
    width: 32px;
    height: 32px;
    border-radius: 40%;

    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: #fff;
      transform: translateY(1px);
      font-size: 16px;
    }
  }

  &.small {
    h3 {
      font-size: 12px;
    }
    .iconBox {
      background: var(--cityColor);
      width: 28px;
      height: 28px;
      i {
        font-size: 13px;
      }
    }
  }
}
