.SaveButton {
  button {
    background-color: var(--sub);
    padding: 8px 16px;
    border: none;
    border-radius: 16px;
    margin: 0;

    span,
    i {
      color: var(--sub_);
    }

    span {
      font-weight: 700;
      text-transform: none;
    }

    i {
      margin-left: 8px;
    }

    &.saved {
      background-color: var(--text4);
    }
  }
}
