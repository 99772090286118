.BuyButton {
  display: flex;
  flex-direction: column;
  button {
    width: 100%;
    margin-bottom: 4px;
    min-height: 64px;
    span {
      color: #fff;
      font-weight: 800;
      font-size: 18px;
      display: block;
    }
    small {
      color: #eee;
    }
    &.softDisabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .agbText {
    text-align: center;
    a {
      color: inherit;
      &:hover {
        // text-decoration: underline;
        color: var(--text1) !important;
      }
    }
  }
}
