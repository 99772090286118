.EntryEditHandler {
  .header {
    background: var(--background);

    h1 {
      color: var(--text1);
      font-size: 26px;
      font-weight: 900;
      margin-top: 0 !important;
    }
    .entryLogo {
      max-height: 50px;
      margin-right: 12px;
    }

    .mobileButtons {
      margin-top: 8px;
      border-top: 1px solid var(--text2);
      padding-top: 12px;
      padding: 16px;
      padding-bottom: 0px;
      i {
        color: var(--sub);
        font-size: 24px;
      }
    }
  }
}
