html {
  --background: #fff;
  --backgroundSub: #f0f0fa;
  --backgroundSubSub: #bdbdc0;

  --main: #003493;
  --main95: #003393e3;
  --main_: #eee;
  --sub: #246ef5;
  --sub50: #246df575;
  --sub30: #246df54a;
  --sub_: #fff;

  --text1: #11151d;
  --text2: #334975;
  --text3: #717283;
  --text4: #d6d9e2;

  --red: #fc5c65;
  --red_: #fff;
  --blue: #4277e9;
  --blue_: #fff;
  --green: #1cbe52;
  --green_: #fff;

  --defaultBorder: 1px solid rgba(0, 0, 0, 0.1);

  --defaultInputBorder: 1px solid rgba(0, 0, 0, 0.15);
  /* --defaultBoxShadow: 5px 5px 13px #cfd9e3, -5px -5px 13px #eff9ff; */
  --defaultBoxShadow: 0.5px 2px 5px rgba(0, 0, 0, 0.1);

  .fad {
    --fa-primary-color: var(--text1);
    --fa-secondary-color: #fc5c65;
    --fa-secondary-opacity: 0.8;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    --background: #000;
    --backgroundSub: #111;
    --backgroundSubSub: #444;

    --red: #fc5c65;
    --red_: #fff;
    --blue: #7298eb;
    --blue_: #fff;
    --green: #12993f;
    --green_: #fff;

    --text1: #d1dbf0;
    --text2: #7298eb;
    --text3: #666c7a;
    --text4: #393b3f;

    --defaultBorder: 1px solid rgba(0, 0, 0, 0.1);

    --defaultInputBorder: 1px solid rgba(0, 0, 0, 0.15);
    /* --defaultBoxShadow: 5px 5px 13px #cfd9e3, -5px -5px 13px #eff9ff; */
    --defaultBoxShadow: 0.5px 2px 5px rgba(0, 0, 0, 0.1);

    .fad {
      --fa-primary-color: var(--text1);
      --fa-secondary-color: #fc5c65;
      --fa-secondary-opacity: 0.8;
    }
  }
}

.red {
  color: var(--red);
}
