.device {
  //Allgemein bei jedem Theme
  --fa-primary-color: var(--iconMainColor);
  --fa-secondary-color: var(--iconSubColor);

  // p;
  // span;
  // h1;
  // h2;
  // h3 {
  //   color: #111 !important;
  // }
  // small {
  //   color: var(--text3);
  // }

  i {
    font-size: 12px;
    --fa-primary-color: #fff !important;
    --fa-secondary-color: rgba(255, 255, 255, 0.6) !important;

    &.fa-battery-full {
      font-size: 18px;
      --fa-primary-color: rgb(28, 235, 28) !important;
      --fa-secondary-color: #fff;
      transform: translateY(2px);
    }
  }

  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 15px;
  }
  .url {
    color: var(--sub);
    margin: 0;
  }

  &.dark {
    --background: #000;
    --backgroundBorder: #222;
    --backgroundSub: #0c0c0c;
    --backgroundSubBorder: #111;
    --text1: #fff;
    --text2: #eee;
    --text3: #aaa;
    --text4: #777;
    --url: #1471cc;
    --blue: #1471cc;
    --red: #d13f3f;
    --green: #5fbf45;
    --backgroundDelete: ##f2dcdf;

    --cityColorSub: #64c4e8;
    --cityColor: #68c78b;
    --cityColorDarker: #162416cc;
    --cityColorLight: #42a667;
    --cityColorUltraLight: #162416;
    --cityColorTiles: #85dea6;
    --cityColorSubDarker: #1b85b5;
    --blur: rgba(255, 255, 255, 0.75);

    --iconMainColor: #0563b0;
    --iconSubColor: #68c78b;
  }

  &.light {
    --background: #fff;
    --backgroundBorder: #ddd;
    --backgroundSub: #f9f9f9;
    --backgroundSubBorder: #eee;
    --text1: #000;
    --text2: #111;
    --text3: #515151;
    --text4: #bbb;
    --url: #263379;
    --blue: #263379;
    --red: #d13f3f;
    --green: #5fbf45;
    --backgroundDelete: #f2dcdf;

    --cityColorSub: #263379;
    --cityColor: #0e6290;
    --cityColorDarker: #0a4b6e;
    --cityColorLight: #52a8d7;
    --cityColorUltraLight: #d3f2e3;
    --cityColorTiles: #0a4b6e;
    --cityColorSubDarker: #1b85b5;
    --blur: "rgba(255;255;255;0.75)";
    --blurContrast: "rgba(0;0;0;0.25)";

    --iconMainColor: #0a4b6e;
    --iconSubColor: #52a8d7;
  }
}
