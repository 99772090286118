.PaymentAddress {
  white-space: pre;

  .output {
    background: var(--backgroundSub);
    padding: 16px 24px;
    border-radius: 12px;
    border: 4px solid transparent;
    cursor: no-drop;
    opacity: 0.8;

    &.error {
      border: 4px solid var(--red);
    }
  }
}
