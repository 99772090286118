.ProfileMenu {
  position: fixed;
  z-index: 5000;
  top: 64px;
  right: 12px;
  max-width: 320px;
  width: 100%;
  padding: 16px;

  background: var(--backgroundSub);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);

  border-radius: 12px;

  .item {
    cursor: pointer;

    span {
      font-size: 20px;
      font-weight: 600;
      display: inline-block;
      i {
        transition: 0.2s;
        margin-right: 8px;
        font-size: 24px;
      }
    }

    &:hover {
      span {
        i {
          transition: 0.2s;
          margin-right: 12px;
          transform: scale(1.12);
        }
      }
    }

    &:focus {
      span {
        i {
          transition: 0.2s;
          margin-right: 32px;
          transform: scale(2);
        }
      }
    }

    &.sign-out {
      color: #cc3b43;
    }
  }
}
