.ColorInput {
  // border: 2px solid var(--text4);
  border-radius: 16px;
  overflow: hidden;
  display: flex;

  .theme {
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px 12px;
    margin-right: 12px;

    background: #fff;
    span {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      margin-left: 8px;
    }

    &.darkMode {
      background: #000;
      span {
        color: #fff;
      }
      input[type="color"] {
        border: 1px solid rgb(219, 219, 219);
      }
    }

    input[type="color"] {
      -webkit-appearance: none;
      width: 32px;
      height: 32px;
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid rgb(43, 43, 43);
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: none;
    }
  }
}
