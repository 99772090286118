.AdminManageVersions {
  .entry {
    max-width: 900px;
    border-bottom: 1px solid var(--text4);

    span {
      color: var(--text2);
      padding-right: 32px;
    }
    small {
      padding-right: 32px;
    }

    button {
      padding: 1px 4px;
      width: 128px;
      margin: 0;
    }

    opacity: 0.5;
    &.isActive {
      opacity: 1;
    }
  }
}
