.ListInput {
  .list {
    display: flex;
    flex: row;
    flex-wrap: wrap;

    .KeyWord {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      font-size: 13px;
      color: var(--text1);
      background: var(--backgroundSub);

      padding: 2px 0px;
      border-radius: 12px;
      margin: 0 4px 8px 0;

      span {
        font-weight: 600;
        cursor: default;
      }

      .prefix {
        font-weight: 300;
        color: var(--sub);
        padding-right: 2px;
        padding-left: 14px;
        &::selection {
          background: inherit;
        }
      }

      &:hover {
        i,
        svg {
          opacity: 0.8;
          transform: scale(1.1);
        }
      }

      i,
      svg {
        font-size: 15px;
        margin-left: 4px;
        color: var(--red);
        cursor: pointer;
        transition: 0.2s;
        opacity: 0;
        transform: scale(0.5);
        &:hover {
          transform: scale(1.3);
          opacity: 1;
        }
      }
    }
  }
}
