.Formular input, .Formular textarea {
    width: 100%;
    border-radius: 12px;
}
.Formular h4 {
    margin: 6px 8px;
}

.Formular p.error {
    color: #da0f0f;
    font-weight: 600;
    font-size: 18px;
}