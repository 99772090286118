.EntryNewPage {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  margin-bottom: 32px;

  img.mittelhessen {
    width: 240px;
    margin: -6px;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  h1 {
    font-size: 24px;
    // margin-bottom: 16px;
    color: var(--text2);
  }
  p {
    color: var(--text2);
    font-size: 16px;
  }

  .fields {
    margin-bottom: 32px;
  }
  .AddDetails {
    span.msg {
      opacity: 0;
      color: red;
      transition: 1s;
    }
    input {
      border: 2px solid transparent;
      transition: 1s;
    }
    .Error {
      input {
        border: 2px solid red;
        transition: 1s;
      }
      span.msg {
        opacity: 1;
        margin: 12px 14px;
        transition: 1s;
      }
    }
  }

  .EmulatorWrapper {
    width: 720px;
    .Emulator {
      position: absolute;
    }
  }

  .SelectBoxes {
    .SelectBox {
      padding: 12;
      .inner {
        h3 {
          color: var(--text1);
        }
        i {
          font-size: 32px;
          color: var(--text4);
          &.fa-check-circle {
            color: var(--green);
          }
        }
      }
    }
  }
  //Vorteile der App rendern
  .features {
    margin-bottom: 64px;
    ul {
      color: var(--text2);
      font-size: 16px;
      list-style-type: circle;
      margin: 8px 0;
      padding: 0;
      &::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: var(--text1); /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        // margin-left: -2em; /* Also needed for space (tweak if needed) */
      }
    }
  }
}
