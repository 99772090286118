@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--background);
  font-family: "Work Sans", "Open Sans", Roboto, Lato, Montserrat,
    "Source Sans Pro", sans-serif;
}
h1,
.h1,
.h2,
.h3,
.subTitle,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", "Helvetica Neue", Arial, sans-serif;
}

* {
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
span {
  font-weight: 400;
}

h1 {
  font-weight: 800;
  color: var(--text1);
  margin-bottom: 12px;
  padding-bottom: 0;
}
h2 {
  color: var(--text2) !important;
  font-size: 24px;
  font-weight: 500;
}
h2,
a,
p {
  color: var(--text1);
}
h2 {
  font-weight: 700;
}

p {
  font-weight: 400;
  font-size: 16px;
  color: var(--text3);
}
span,
i {
  color: var(--text1);
}
small {
  color: var(--text3);
}
button {
  border-radius: 8px !important;
  background: var(--sub);
  color: var(--sub_);
  border: none; // 1px solid var(--main);
  box-shadow: 0 0 2px 0px var(--main);
  padding: 8px 20px;

  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 12px;
  margin-right: 12px;

  i {
    color: var(--sub_);
  }

  // Abbrechen Style (Roter Button )
  &.abort {
    background: var(--red);
    color: var(--red_);
    i {
      color: var(--red_);
    }
  }
}

input.caps,
textarea.caps {
  text-transform: uppercase;
}

input:not([type="color"]),
textarea {
  border: none;
  display: block;
  width: 100%;

  font-size: 14px;

  letter-spacing: 1px;

  padding: 6px 16px;
  border-radius: 10px;
  border: var(--defaultInputBorder);
  background: var(--backgroundSub);

  color: var(--text1) !important;
}

.input-group {
  background: var(--backgroundSub);
  display: flex;
  flex-direction: row;
  // align-items: stretch;

  border-radius: 10px;
  overflow: hidden;
  border: var(--defaultInputBorder);

  * {
    border: none !important;
    display: flex;
  }

  .form-control:focus {
    background: var(--backgroundSub);
  }

  .icon {
    align-items: baseline;
    height: 100%;
    padding: 8px 12px;
    border: var(--defaultInputBorder);
    background: var(--backgroundSub);
    font-size: 20px;
    i {
      padding: 2px;
      transform: translateY(1.5px);
    }
  }
}

.inputGroup {
  display: flex;
  flex-direction: row;

  border-radius: 10px;
  overflow: hidden;

  > * {
    border-radius: 0 !important;
    height: 100%;
    border: var(--defaultInputBorder);
    background: var(--backgroundSub);
  }
  i {
    font-size: 16px;
    padding: 8px;
  }
}
// ::selection {
//   background-color: initial;
// }
input::selection,
textarea::selection {
  background-color: var(--text1);
  color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--backgroundSub) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--sub) !important;
}

input:disabled {
  background: transparent;
  font-weight: 600;
  border: 2px dotted var(--text4);
  cursor: no-drop;
}
