.AdminUserList {
  height: 100vh;
  overflow-y: auto;

  table thead,
  table tfoot {
    position: sticky;
    background: var(--backgroundSub);
  }
  table thead {
    inset-block-start: 0; /* "top" */
  }
  table tfoot {
    inset-block-end: 0; /* "bottom" */
  }
}
