.SelectBoxes {
  .SelectBox {
    padding: 4px;

    .inner {
      border: 1px solid var(--text4);
      padding: 20px 20px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;

      cursor: pointer;
      min-height: 70px;

      .icon {
        img {
          height: 52px;
          border-radius: 35%;
          box-shadow: 0 0 2px 0px var(--text4);
        }
      }

      .benefits {
        font-weight: 600;
        color: var(--sub);
        padding: 12px 0;
      }

      p {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .title {
        font-size: 14px;
        font-weight: 700;
      }
      .description {
        font-size: 12px;
        font-weight: 500;
      }

      &.isActive {
        border: 1px solid var(--sub);
        box-shadow: 0 0 4px 2px var(--sub);
      }
    }
  }
}
