.EntryListPage {
  display: flex;

  .page {
    height: 50vh;
    overflow: hidden;
  }

  i {
    font-size: 32px;
  }

  .token {
    max-width: 100%;
    word-break: break-all;
  }
  p {
    font-size: 16px !important;
  }

  button {
    font-size: 16px;
    i,
    svg {
      font-size: 16px;
    }
  }

  .EntryListItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.text {
      white-space: nowrap;
      text-overflow: ellipsis;

      h4 {
        color: var(--text2);
      }
    }

    img.entryIcon {
      width: 100%;
      border-radius: 12px;
    }
  }

  .inputGroup {
    width: 280px;
  }

  .row {
    max-height: calc(100vh - 213px);
    overflow-y: scroll;
  }
}
