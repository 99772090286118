.PageLogin {
  box-sizing: border-box;

  .left {
    background: var(--main);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-right: 12px solid var(--sub);

    .header {
      background: var(--background);
      margin-bottom: 32px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .MittelhessenSkyline {
        // height: 200px;
        padding-top: 64px;
      }
      .DashdesignBackendLogo {
        padding: 44px 32px;
        position: absolute;
      }
    }

    h1 {
      color: var(--sub_);
      font-size: 24px;
      margin-bottom: 4px;
    }
    p {
      color: #cccc;
      // font-size: 24px;
    }
    a {
      color: var(--sub_);
      opacity: 0.5;
    }

    .inner {
      margin-bottom: 220px;

      input {
        min-height: 40px;
        border: none;
        &.email {
          text-transform: lowercase;
          &::placeholder {
            text-transform: none;
          }
        }
      }
      button {
        font-weight: 600;
        font-size: 16px;
      }
      .errorText {
        background: var(--red);
        padding: 8px 16px;
        color: var(--red_);
        font-weight: 600;
        border-radius: 16px;
        i {
          margin-right: 6px;
        }
      }
    }
  }
  .partner {
    img {
      height: 44px;
      border-radius: 40%;
      margin: 0 8px;
      background-color: #fff;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
      &#hessen {
        padding: 6px;
      }
      &#dashdesign {
        padding: 2px;
      }
    }
  }

  .imageBox {
    height: 100vh;
    background-size: cover;
    background-position: left;

    display: flex;
    padding: 32px;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
