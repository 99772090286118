.Tip {
  margin-left: 8px;
  display: flex;
  align-items: center;

  i {
    color: var(--text3);
    font-size: 18px;
  }

  .popup {
    .textBox {
      box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1);
      background: var(--backgroundSub);
      border-radius: 12px;
      padding: 10px 16px;
    }

    z-index: 100;
    display: none;
    padding-left: 32px;
    position: absolute;
    max-width: 240px;
    padding-left: 32px;

    &:hover {
      display: block;
    }
  }

  &:hover {
    .popup {
      display: block;
    }
  }
}
