.delete {
    position: absolute;
    width: 5%;
    right: 5%;
    top: 7%;
    cursor: pointer;
}

.anim {
    transition: opacity 0.1s ease-in;
}

.image-container {
    display: inline-block;
    position: relative;
}

.visible-on {
    opacity: 1;
}

.visible-off {
    opacity: 0;
}