.IFrameEditPage {
  .CodeBox {
  }

  iframe {
    border: 2px dotted var(--text3);
  }

  hr {
    margin-top: 48px;
    margin-bottom: 64px;
  }
}
