.EventsPage {
  .EditEvent {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background: var(--background);

    .inner {
    }
  }
}
