.message {
  transition: opacity 5s ease-in;
}

.anim-msg {
  /* opacity: 0; */
}

.cropper-page {
}
